body {
  margin: 0;
  padding: 0;
  font-family:'Noto Sans TC', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main{
  margin-top: 56px;
}

.row+.row {
  margin-top: 25px;
}

.text-small{
  font-size: 12px;
}